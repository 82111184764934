import { createTheme } from '@mui/material/styles';
import { themeOptions } from './theme-options';

const { typography, ...other } = themeOptions;
const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        disableGutters: ({ theme }) => ({
          paddingLeft: 0,
          paddingRight: 0,
          [theme.breakpoints.up('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        }),
        root: ({ theme }) => ({
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        }),
      },
    },
  },
  typography: {
    fontFamily: 'Quicksand, sans-serif',
    h1: {
      fontSize: '1.5rem',
    },
    h2: {
      fontSize: '1.25rem',
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1.25rem',
    },
    subtitle1: {
      fontSize: '1rem',
    },
    subtitle2: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '1rem',
    },
    body2: {
      fontSize: '1rem',
    },
    ...typography,
  },
  ...other,
});

export default theme;
