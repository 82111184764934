import type { Children } from '../../types';

import * as React from 'react';
import Ancestor from '../../utils/ancestor';

interface ResizeContextInterface {
  containerRef: React.MutableRefObject<any | undefined>;
  handleResize: Function;
  setAncestorResizeHandler: React.Dispatch<React.SetStateAction<Boolean>>;
}

interface ResizeContextProviderProps extends Children {
  ancestorResizeHandler: Boolean;
  setAncestorResizeHandler: React.Dispatch<React.SetStateAction<Boolean>>;
  containerRef: React.MutableRefObject<any | undefined>;
}

const ResizeContext = React.createContext(null as any);

export const useResizeContext = (): ResizeContextInterface => {
  const context = React.useContext(ResizeContext);

  if (context === undefined) {
    throw new Error('useResizeContext must be used within ResizeContextProvider.');
  }

  return context as ResizeContextInterface;
};

// eslint-disable-next-line import/prefer-default-export
export const ResizeContextProvider = (props: ResizeContextProviderProps) => {
  const { children, containerRef, ancestorResizeHandler, setAncestorResizeHandler } = props;
  // Handle Resize Ancestor
  const ancestor = new Ancestor();

  function handleResize(fullscreen: any, element: any) {
    ancestor.postDimensions(fullscreen, element);
  }

  // Initially Resizing page when DOM Loaded
  React.useEffect(() => {
    if (typeof window !== 'undefined' && containerRef.current) {
      window.addEventListener('load', () => handleResize(false, containerRef.current));
    }
  }, [containerRef.current, ancestorResizeHandler]);

  // Resizing on Browser Height Changed
  React.useEffect(() => {
    if (!ancestorResizeHandler) {
      window.addEventListener('resize', () => handleResize(false, containerRef.current));
    }
    return () => {
      if (ancestorResizeHandler) {
        setAncestorResizeHandler(false);
      }
    };
  }, []);

  const resizeContextValues: ResizeContextInterface = {
    containerRef,
    handleResize,
    setAncestorResizeHandler,
  };

  return <ResizeContext.Provider value={resizeContextValues}>{children}</ResizeContext.Provider>;
};
