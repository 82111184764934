import { getQueryParams } from "../get-query-params";

export default class Ancestor {
  /**
   * @constructor
   * @returns {Ancestor.instance}
   */
  constructor() {
    if (!Ancestor.instance) {
      Ancestor.instance = this;

      const params = getQueryParams();

      /**
       * Make sure that window, parent and document are properly checked on constructor initialization,
       * otherwise we will hit an error when the site using this component has server side rendering, as there
       * is no window, parent and document API on servers.
       * @type {string|undefined}
       */
      let url;
      if (params.parent_url) {
        url = params.parent_url;
      }
      // Todo: This still seems to break, when loading our sites within our SMS.
      // } else if (typeof parent !== 'undefined') {
      //   url = parent.location.origin;
      // } else if (typeof document !== 'undefined') {
      //   url = document.location.origin
      // }

      this._context = {
        url: undefined,
      };

      this.context = { ...this._context, url };
    }

    return Ancestor.instance;
  }

  set context(obj) {
    this._context = obj;
  }

  get context() {
    return this._context;
  }

  postMessage(eventName, data) {
    if (!this.context?.url) return;

    parent.postMessage(
      {
        name: eventName,
        data: JSON.stringify(data),
      },
      this.context.url
    );
  }

  postDimensions(fullscreen, element) {
    if (!this.context?.url) return;

    parent.postMessage(
      {
        name: "orbit:change:fullscreen",
        data: JSON.stringify({
          enabled: fullscreen,
        }),
      },
      this.context.url
    );

    if (!fullscreen && element) {
      let height = 0;
      let width = 0;
      if (element.children.length > 0) {
        const { clientHeight, offsetHeight, scrollHeight } = element;
        const { clientWidth, offsetWidth, scrollWidth } = element;
        height = clientHeight || offsetHeight || scrollHeight;
        width = clientWidth || offsetWidth || scrollWidth;
      }

      const dimensions = { width: Math.round(width), height: Math.round(height) };
      // Element is probably hidden.
      if (dimensions.height === 0) return;
      parent.postMessage(
        {
          name: "orbit:change:dimensions",
          data: JSON.stringify({
            height: dimensions.height,
            width: dimensions.width,
          }),
        },
        this.context.url
      );
    }
  }
}
