import type { Children } from '../../types';
import type { HighlightEnabledVariantsProps } from './Highlight';

import * as React from 'react';

interface HighlightContextInterface {
  enabled: boolean;
  enabledVariants: HighlightEnabledVariantsProps[];
}

interface HighlightContextProviderProps extends Children {
  enable: boolean;
  enableVariants: HighlightEnabledVariantsProps[];
}

const HighlightContext = React.createContext(null as any);
export const useHighlightContext = (): HighlightContextInterface => {
  const context = React.useContext(HighlightContext);
  if (context === undefined) {
    throw new Error('useHighlightContext must be used within HighlightContextProvider.');
  }

  return context as HighlightContextInterface;
}

// eslint-disable-next-line import/prefer-default-export
export const HighlightContextProvider = (props: HighlightContextProviderProps) => {
  const { children, enable, enableVariants } = props;
  const [enabled, setEnabled] = React.useState<boolean>(false);
  const [enabledVariants, setEnabledVariants] = React.useState<HighlightEnabledVariantsProps[]>([]);
  const allVariants: HighlightEnabledVariantsProps[] = [
    // MuiTypography variants
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'button',
    'caption',
    'overline',

    // MuiButton variants
    'text',
    'outlined',
    'contained',
  ];

  const messageHandler = (event: MessageEvent) => {
    if (event.data?.data && event.data?.name === 'orbit:change:highlight:toggle') {
      // console.info('[highlight] orbit:change:highlight:toggle received!');

      const data = JSON.parse(event.data.data);

      setEnabled(data?.enabled);
      setEnabledVariants(data?.enabled ? allVariants : []);
    } else if (event.data?.data && event.data?.name === 'orbit:change:highlight:specific') {
      // console.info('[highlight] orbit:change:highlight:specific received!');

      const data = JSON.parse(event.data.data);

      setEnabled(data?.enabled);

      if ((data?.variants || []).includes('global')) {
        setEnabledVariants(allVariants);
      } else if ((data?.variants || []).includes('button')) {
        setEnabledVariants([
          'text',
          'outlined',
          'contained',
        ]);
      } else {
        setEnabledVariants(data?.variants || []);
      }
    }
  };

  // React.useEffect(() => {
  //   console.info('[highlight]', enabled, enabledVariants);
  // }, [enabled, enabledVariants]);

  React.useEffect(() => {
    setEnabledVariants(enableVariants);
  }, [enableVariants]);

  React.useEffect(() => {
    setEnabled(enable);
  }, [enable]);

  React.useEffect(() => {
    window.addEventListener('message', messageHandler);
    return () => {
      window.removeEventListener('message', messageHandler);
    };
  }, []);

  const cursorContextValues: HighlightContextInterface = {
    enabled,
    enabledVariants,
  };

  return (
    <HighlightContext.Provider value={cursorContextValues}>
      {children}
    </HighlightContext.Provider>
  )
};
