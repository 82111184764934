import type { Children, IUserProps, IUserFamily, Pegi } from '../../types';
import { IKidForm } from '../../types/dto';

import * as React from 'react';

import useLocalStorage from '../../utils/use-local-storage';
import { useRouter } from 'next-translate-routes';
import { useAppContext } from '../AppContext';
import {
  profileMe,
  myFamily,
  addKid,
  editOwner,
  editKid,
  deleteKid,
  activateKid,
  deactivateKid,
  logout,
  validatePin,
  switchUser,
  kidEditSelf,
  getPegiList,
} from '../../app/api';
import { UserRole } from '../../types/enum';

interface AuthenticationContextInterface {
  userToken?: string;
  userCredentials?: IUserProps;
  userFamily?: IUserFamily;
  addNewKid: (form: IKidForm) => Promise<any[] | undefined>;
  getFamily: () => Promise<any[] | undefined>;
  currentUser?: IUserProps;
  currentUserSet: (user: IUserProps) => void;
  userCredentialsSet: (user: IUserProps) => void;
  editUser: (owner: boolean, form: IKidForm) => Promise<any[] | undefined>;
  editKidSelf: (form: IKidForm) => Promise<IUserProps | undefined>;
  deleteKidAccount: (kidId: string) => Promise<any[] | undefined>;
  activateKidAccount: (kidId: string) => Promise<any[] | undefined>;
  deactivateKidAccount: (kidId: string) => Promise<any[] | undefined>;
  profileLogout: () => Promise<void>;
  enterPin: (pin: string) => Promise<any[] | undefined>;
  switchAccount: (pin: string, zidp: string) => Promise<any[] | undefined>;
  isUserFirstEntry: boolean;
  pegiList: Pegi[] | undefined;
}

interface AuthenticationContextProviderProps extends Children {}

const AuthenticationContext = React.createContext(null as any);

export const useAuthenticationContext = (): AuthenticationContextInterface => {
  const context = React.useContext(AuthenticationContext);

  if (context === undefined) {
    throw new Error('useAuthenticationContext must be used within AuthenticationContextProvider.');
  }

  return context as AuthenticationContextInterface;
};

// eslint-disable-next-line import/prefer-default-export
export const AuthenticationContextProvider = (props: AuthenticationContextProviderProps) => {
  const { children } = props;
  const router = useRouter();
  const appContext = useAppContext();
  const { componentOptions } = appContext.useConfig();

  const [userToken, setUserToken] = useLocalStorage<string | undefined>('user-token', '');
  const [userCredentials, setUserCredentials] = React.useState<IUserProps>({} as IUserProps);
  const [currentUser, setCurrentUser] = useLocalStorage<IUserProps>('current-user', {} as IUserProps);

  const [userFamily, setUserFamily] = React.useState<IUserProps[]>([] as IUserFamily);
  const [pegiList, setPegiList] = React.useState<Pegi[]>([] as Pegi[]);

  const subMode = componentOptions?.component_project_subscription_mode;

  React.useEffect(() => {
    if (router.query?.['x-session-id']) {
      setUserToken(router.query['x-session-id'] as string);
      router.push('/profiles');
    }
  }, [router.query]);

  const profileLogout = async () => {
    await logout({ xSessionId: userToken as string });
    setUserToken('');
    setUserCredentials({} as IUserProps);
    setUserFamily([] as IUserFamily);
    setCurrentUser({} as IUserProps);
    router.push('/');
  };

  React.useEffect(() => {
    if (subMode && userToken !== '') {
      getProfileDetails();
      if (userFamily?.length === 0 || pegiList?.length === 0) {
        getFamily();
        getPegis();
      }
    }
  }, [userToken]);

  const getProfileDetails = async () => {
    const response = await profileMe({ xSessionId: userToken as string });
    if (response) {
      setUserCredentials(response as any);
    } else {
      await profileLogout();
    }
    return response;
  };

  const getFamily = async () => {
    const response = await myFamily({ xSessionId: userToken as string });
    if (response) {
      setUserFamily(response as any);
    }
    return response;
  };

  const getPegis = async () => {
    const response = await getPegiList();
    if (response) {
      setPegiList(response as Pegi[]);
    }
    return response;
  };

  const addNewKid = async (form: IKidForm) => {
    const response = await addKid({ xSessionId: userToken as string }, form);
    return response;
  };

  const switchAccount = async (pin: string, id: string) => {
    const response: any = await switchUser({ xSessionId: userToken as string }, pin, id);
    if (response?.role) {
      setCurrentUser(response as any);
      if (response?.role.id === 'owner') {
        setUserCredentials(response as any);
      }
    }
    return response;
  };

  const currentUserSet = (user: IUserProps) => {
    setCurrentUser(user as IUserProps);
  };

  const userCredentialsSet = (user: IUserProps) => {
    setUserCredentials(user as IUserProps);
  };

  const editUser = async (owner: boolean, form: IKidForm) => {
    let response;
    if (owner === true) {
      response = await editOwner({ xSessionId: userToken as string }, form);
    } else {
      response = await editKid({ xSessionId: userToken as string }, form);
    }
    return response;
  };

  const editKidSelf = async (form: IKidForm) => {
    const response = await kidEditSelf({ xSessionId: userToken as string }, form);
    if (userFamily && response) {
      const _userFamily = userFamily.filter((user) => user.id === response?.id);
      setUserFamily([..._userFamily, response as any]);
      setCurrentUser(response);
    }
    return response;
  };

  const deleteKidAccount = async (kidId: string) => {
    const response = await deleteKid({ xSessionId: userToken as string }, kidId);
    return response;
  };

  const activateKidAccount = async (kidId: string) => {
    const response = await activateKid({ xSessionId: userToken as string }, kidId);
    return response;
  };

  const deactivateKidAccount = async (kidId: string) => {
    const response = await deactivateKid({ xSessionId: userToken as string }, kidId);
    return response;
  };

  const enterPin = async (pin: string) => {
    const response = await validatePin({ xSessionId: userToken as string }, pin);
    return response;
  };

  React.useEffect(() => {
    if (subMode && userToken !== '' && !currentUser?.id && router.pathname !== '/profiles') {
      router.push('/profiles');
    }
  }, [currentUser?.id]);

  const [isUserFirstEntry, setIsUserFirstEntry] = React.useState<boolean>(false);

  React.useEffect(() => {
    ownerFirstEntry();
  }, [userCredentials]);

  const ownerFirstEntry = () => {
    const onlyOwner =
      userFamily?.length === 1 &&
      userFamily[0].role.id === UserRole.PARENT &&
      userCredentials?._pin === 'null from self';
    if (onlyOwner) {
      setIsUserFirstEntry(true);
    }
  };

  const authenticationContextValues: AuthenticationContextInterface = {
    userToken,
    userCredentials,
    userFamily,
    addNewKid,
    getFamily,
    profileLogout,
    currentUser,
    currentUserSet,
    editUser,
    editKidSelf,
    deleteKidAccount,
    activateKidAccount,
    deactivateKidAccount,
    enterPin,
    isUserFirstEntry,
    switchAccount,
    userCredentialsSet,
    pegiList,
  };

  return (
    <AuthenticationContext.Provider value={authenticationContextValues}>{children}</AuthenticationContext.Provider>
  );
};
