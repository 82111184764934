export const getQueryParams = () => {
  let match;
  const pl = /\+/g;
  const search = /([^&=]+)=?([^&]*)/g;
  const decode = (s: string) => decodeURIComponent(s.toLowerCase().replace(pl, ' '));
  const query = typeof window !== 'undefined' ? window.location.search.substring(1) : '';
  const urlParams = {};

  while ((match = search.exec(query))) {
    // @ts-ignore
    urlParams[decode(match[1])] = decode(match[2]);
  }

  return urlParams;
};