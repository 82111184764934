import type { NextRouter } from 'next/router';
import { translateUrl } from 'next-translate-routes';

export const formatSlug = (slug: string, locale?: string, defaultLocale?: string) => {
  return locale === defaultLocale ? `/${cleanSlug(slug)}` : `/${locale}/${cleanSlug(slug)}`.replace(/\/+$/, '');
}

export const cleanSlug = (slug: string) => slug.replace(/^\/|\/$/g, '');

/**
 * Translate our path, and return a complete translated URL.
 * Todo: 'next-translate-routes' doesn't provide a way to fetch the translated URL with locale prefixed?
 * @param router NextRouter
 * @param translateLocale The locale that we want to translate to.
 * @param format Decide if you want to add slashes and locale for a complete path.
 */
export const translatePath = (router: NextRouter, translateLocale?: string, format: boolean = true) => {
  const locale = translateLocale || router.locale || 'en';
  const slug = translateUrl(router, locale).pathname || '';
  return format
    ? formatSlug(slug, locale, router.defaultLocale)
    : cleanSlug(slug);
};

/**
 * Return an array of objects containing all translations of a route.
 * @param router NextRouter
 */
export const getLocalizedPaths = (router: NextRouter) => {
  return router.locales?.map((locale: string) => ({
    locale,
    path: `${translatePath(router, locale)}`,
  })) || [];
};

export const getLocalizedSlugs = (router: NextRouter) => {
  return router.locales?.map((locale: string) => ({
    locale,
    slug: `${translatePath(router, locale, false)}`,
  })) || [];
};