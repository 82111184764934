import type { ComponentOptionsProps, CustomThemeOptionsProps } from '../../types';

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useCursorContext } from './index';
import Ancestor from '../../utils/ancestor';

export interface CursorProps {
  active: number;
}

interface CursorHandlerProps {
  name: string;
  configuration: FormGeneratorConfigurationProps[];
  styles: FormGeneratorThemeProps[];
  onMouseEnter?: React.FunctionComponent;
  onMouseLeave?: React.FunctionComponent;
}

interface FormGeneratorProps {
  type: React.HTMLInputTypeAttribute | 'divider' | 'header';
  label?: string;
  recipe?: string[];
  default?: any; // Todo: Get at least the type of the keyof CustomThemeOptionsProps | ComponentOptionsProps?
  options?: string[];
  helperText?: string;
  placeholder?: string;
  startAdornment?: string;
  endAdornment?: string;
}

interface FormGeneratorThemeProps extends FormGeneratorProps {
  name?: keyof CustomThemeOptionsProps;
}

interface FormGeneratorConfigurationProps extends FormGeneratorProps {
  name?: keyof ComponentOptionsProps | keyof CustomThemeOptionsProps;
}

const isTouchDevice =
  typeof window !== 'undefined' &&
  typeof navigator !== 'undefined' &&
  ('ontouchstart' in window || navigator.maxTouchPoints > 0);

export const useCursorHandlers = (props: CursorHandlerProps) => {
  const { enabled, setCursor } = useCursorContext();
  const [ancestor] = React.useState(new Ancestor());
  const theme = useTheme();

  const onClick = React.useCallback((event: React.MouseEvent) => {
    // Todo: HUZ2ERA-1180 - This doesn't seem to work for our NextJS Links. Seems the onclick is overwritten or something.
    event.preventDefault();
    event.stopPropagation();
    ancestor.postMessage('orbit:create:form', props);
  }, []);

  const onMouseEnter = React.useCallback((event: React.MouseEvent) => {
    if (props.onMouseEnter) {
      props.onMouseEnter(event);
    }

    const element = event.currentTarget as HTMLInputElement;
    element.style.outline = `2px dotted ${theme.palette.info.main}`;
    element.style.outlineOffset = '-2px';

    setCursor((previousState: CursorProps) => ({ ...previousState, active: previousState.active + 1 }));
  }, []);

  const onMouseLeave = React.useCallback((event: React.MouseEvent) => {
    if (props.onMouseLeave) {
      props.onMouseLeave(event);
    }

    const element = event.currentTarget as HTMLInputElement;
    element.style.outline = 'unset';
    element.style.outlineOffset = 'unset';

    setCursor((previousState: CursorProps) => ({ ...previousState, active: previousState.active - 1 }));
  }, []);

  if (isTouchDevice || !enabled) return;

  return { onClick, onMouseEnter, onMouseLeave };
};

const useMousePosition = () => {
  const [position, setPosition] = React.useState({
    clientX: 0,
    clientY: 0,
  });

  const updatePosition = (event: MouseEvent) => {
    const { clientX, clientY } = event;

    setPosition({
      clientX,
      clientY,
    });
  };

  React.useEffect(() => {
    document.body.addEventListener('mousemove', updatePosition, false);
    document.body.addEventListener('mouseenter', updatePosition, false);

    return () => {
      document.body.removeEventListener('mousemove', updatePosition);
      document.body.removeEventListener('mouseenter', updatePosition);
    };
  }, []);

  return position;
};

export const Cursor = () => {
  const theme = useTheme();
  const { enabled, cursor } = useCursorContext();
  const { clientX, clientY } = useMousePosition();
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);
    document.body.addEventListener('mouseenter', handleMouseEnter);
    document.body.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.body.removeEventListener('mouseenter', handleMouseEnter);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  if (isTouchDevice || !enabled) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 9999,
        pointerEvents: 'none',
      }}
    >
      <svg
        width={50}
        height={50}
        viewBox="0 0 50 50"
        style={{
          position: 'absolute',
          pointerEvents: 'none',
          left: clientX,
          top: clientY,
          // TODO: extra check on clientX needed here
          // because mouseleave event not always firing
          // when slowly exiting left side of browser
          opacity: isVisible && clientX > 1 ? 1 : 0,
          transform: `translate(-50%, -50%) scale(1)`,
          stroke: cursor.active > 0 ? theme.palette.info.main : theme.palette.info.dark,
          strokeWidth: 1,
          fill: cursor.active > 0 ? 'rgba(255, 255, 255, .5)' : theme.palette.info.main,
          transition: 'transform .2s ease-in-out',
        }}
      >
        <circle cx="25" cy="25" r="8" />
      </svg>
    </div>
  );
};
